.drop-area {
    &.highlight {
        border-color: #93b6ed;
        box-shadow: 0 0 0 .25rem rgba(39, 108, 218, .25);
        background-color: #F2F6FD;
    }
}
#preview-container {
    max-width: 126px;
    &:not(:has(img)) {
        display: none !important;
    }
}
